<template>
  <div class="license">
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2">
          <!-- eslint-disable:cle max-len -->
          <div class="editor">
            <h1>Licencja</h1>

            <p>
              Zapraszamy wkrótce
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'License',
};
</script>
